
// // Custom Theming for Angular Material
// // For more information: https://material.angular.io/guide/theming
// @import '~@angular/material/theming';
// // Plus imports for other components in your app.
// @import '~@angular/material/prebuilt-themes/indigo-pink.css';
// @import '~@angular/material/datepicker/datepicker-theme.scss';
// // Include the common styles for Angular Material. We include this here so that you only
// // have to load a single css file for Angular Material in your app.
// // Be sure that you only ever include this mixin once!
// @include mat-core();

// // Define the palettes for your theme using the Material Design palettes available in palette.scss
// // (imported above). For each palette, you can optionally specify a default, lighter, and darker
// // hue. Available color palettes: https://material.io/design/color/
// $Fecoomeva-primary: mat-palette($mat-indigo);
// $Fecoomeva-accent: mat-palette($mat-pink, A200, A100, A400);

// // The warn palette is optional (defaults to red).
// $Fecoomeva-warn: mat-palette($mat-red);

// // Create the theme object. A theme consists of configurations for individual
// // theming systems such as "color" or "typography".
// $Fecoomeva-theme: mat-light-theme((
//   color: (
//     primary: $Fecoomeva-primary,
//     accent: $Fecoomeva-accent,
//     warn: $Fecoomeva-warn,
//   )
// ));

// // Include theme styles for core and each component used in your app.
// // Alternatively, you can import and @include the theme mixins for each component
// // that you are using.
// @include angular-material-theme($Fecoomeva-theme);


// // Custom Theming for Angular Material
// // For more information: https://material.angular.io/guide/theming
// @import '~@angular/material/theming';
// // Plus imports for other components in your app.

// // Include the common styles for Angular Material. We include this here so that you only
// // have to load a single css file for Angular Material in your app.
// // Be sure that you only ever include this mixin once!
// @include mat-core();

// // Define the palettes for your theme using the Material Design palettes available in palette.scss
// // (imported above). For each palette, you can optionally specify a default, lighter, and darker
// // hue. Available color palettes: https://material.io/design/color/
// $Fecoomeva-primary: mat-palette($mat-indigo);
// $Fecoomeva-accent: mat-palette($mat-pink, A200, A100, A400);

// // The warn palette is optional (defaults to red).
// $Fecoomeva-warn: mat-palette($mat-red);

// // Create the theme object. A theme consists of configurations for individual
// // theming systems such as "color" or "typography".
// $Fecoomeva-theme: mat-light-theme((
//   color: (
//     primary: $Fecoomeva-primary,
//     accent: $Fecoomeva-accent,
//     warn: $Fecoomeva-warn,
//   )
// ));

// // Include theme styles for core and each component used in your app.
// // Alternatively, you can import and @include the theme mixins for each component
// // that you are using.
// @include angular-material-theme($Fecoomeva-theme);

@font-face {
  font-family: "Poppins";
  src: url("assets/Fonts/Poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: "PoppinsB";
  src: url("assets/Fonts/Poppins/Poppins-Bold.ttf");
}

head,
body {
  margin: 0%;
  padding: 0%;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

// @media screen and (max-width: 1200px) {
//   body {
//     overflow-y: visible;
//     overflow-x: hidden;
//   }
// }

// @media screen and (max-width: 640px) and (min-width: 361px) {
//   body {
//     overflow-y: visible;
//   }
// }

div,
p,
small,
th,
td {
  font-family: "Poppins";
}

.poppins {
  font-family: "Poppins";
}

.btnCoopedac, .btnCoopedacComunicar {
  background: #3c86c6;
  border-radius: 22px !important;
  color: #ffffff;
  border-width: inherit;
  padding: 1vh;
  font-size: 2vh;
  color: #fff;
  border: 1px solid #3c86c6;
}

.btnCoopedacWhite {
  background: #fff;
  border-radius: 22px !important;
  color: #76c25c;
  border-width: inherit;
  padding: 1vh;
  font-size: 2vh;
  border: 1px solid #76c25c;
}

.btnAtras {
  background: #3c86c6;
  border-radius: 22px !important;
  color: #ffffff;
  border-width: inherit;
  font-size: 2vh;
}

.btnCoopedac:hover {
  background-color: #2f6da3;
  cursor: pointer;
}

.btnCoopedac:focus {
  outline: none;
}

.btnCoopedacAdmin {
  background: #76c25c;
  border-radius: 22px !important;
  color: #ffffff;
  border-width: inherit;
  padding: 1vh;
  font-size: 2vh;
}

.btnCoopedacAdmin2 {
  background: #F58634;
  border-radius: 22px !important;
  color: #ffffff;
  border-width: inherit;
  padding: 1vh;
  font-size: 2vh;
}

.btnCoopedacAdmin:hover {
  background-color: #56a53c;
  cursor: pointer;
}

.btnCoopedacAdmin:focus {
  outline: none;
}

.mt-1 {
  margin-top: 1vh !important;
}

.mt-2 {
  margin-top: 2vh !important;
}

.mt-3 {
  margin-top: 3vh !important;
}

.mt-4 {
  margin-top: 4vh !important;
}

.mt-5 {
  margin-top: 5vh !important;
}

.ml-1 {
  margin-left: 1vh !important;
}

.ml-2 {
  margin-left: 2vh !important;
}

.ml-3 {
  margin-left: 3vh !important;
}

.txtFooter {
  margin-left: 10%;
}

/* .p-dialog .p-dialog-header {
  border-radius: 5vh 5vh 0vh 0vh;
}

.p-dialog .p-dialog-content {
  border-radius: 0vh 0vh 5vh 5vh;
}

.p-dialog {
  border-radius: 5vh 5vh 5vh 5vh;
}
 */
.p-invalid {
  font-size: 2vh;
}

.p-inputtext {
  height: 36px;
}

.p-dropdown,
.p-calendar,
.p-inputtext {
  width: 100% !important;
}

.p-inputnumber-input {
  flex: 1 1 auto;
  width: 51.4vh !important;
}

.p-field {
  margin-bottom: 2vh;
}

// .p-dropdown-label,
// .p-inputtext {
//   font-size: 2.5vh;
//   padding: 1.5vh;
// }

.p-dropdown-trigger {
  width: 5vh;
}

.p-datepicker-trigger {
  width: 7vh;
  padding: 1vh;
}

.p-dropdown-trigger-icon,
.pi-calendar {
  font-size: 3vh;
}

.p-checkbox {
  margin-left: 19vh;
}

.p-checkbox-label {
  font-style: normal;
  font-weight: normal;
  font-size: 1.7vh;
  align-items: center;
  text-align: center;
  color: #1059a9;
}

.txtTitulo {
  -webkit-text-stroke: 0.4px #89AF51;
}

.font-poppins-b {
  font-family: PoppinsB;
}

.flat-button {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}
.flat-button:active {
  box-shadow: inset 4px 4px 4px rgba(100, 100, 100, .5) !important;
}

.text-small {
  font-size: .8em;
}

.contenido {
  height: 80vh;
}

.a-link:hover {
  text-decoration: underline;
}

.text-em-01 { font-size: .1em }
.text-em-02 { font-size: .2em }
.text-em-03 { font-size: .3em }
.text-em-04 { font-size: .4em }
.text-em-05 { font-size: .5em }
.text-em-06 { font-size: .6em }
.text-em-07 { font-size: .7em }
.text-em-08 { font-size: .8em }
.text-em-09 { font-size: .9em }
.text-em-10 { font-size: .10em }
.text-em-11 { font-size: .11em }
.text-em-12 { font-size: .12em }
.text-em-13 { font-size: .13em }
.text-em-14 { font-size: .14em }
.text-em-15 { font-size: .15em }
.text-em-16 { font-size: .16em }
.text-em-17 { font-size: .17em }
.text-em-18 { font-size: .18em }
.text-em-19 { font-size: .19em }
.text-em-20 { font-size: .20em }

.text-graduable-height-1 { font-size: 1vh }
.text-graduable-height-1_5 { font-size: 1.5vh }
.text-graduable-height-2 { font-size: 2vh }
.text-graduable-height-2_5 { font-size: 2.5vh }
.text-graduable-height-3 { font-size: 3vh }
.text-graduable-height-3_5 { font-size: 3.5vh }
.text-graduable-height-4 { font-size: 4vh }
.text-graduable-height-4_5 { font-size: 4.5vh }
.text-graduable-height-5 { font-size: 5vh }
.text-graduable-height-5_5 { font-size: 5.5vh }
.text-graduable-height-6 { font-size: 6vh }
.text-graduable-height-6_5 { font-size: 6.5vh }
.text-graduable-height-7 { font-size: 7vh }
.text-graduable-height-7_5 { font-size: 7.5vh }
.text-graduable-height-8 { font-size: 8vh }
.text-graduable-height-8_5 { font-size: 8.5vh }
.text-graduable-height-9 { font-size: 9vh }
.text-graduable-height-9_5 { font-size: 9.5vh }
.text-graduable-height-10 { font-size: 10vh }
.text-graduable-height-10_5 { font-size: 10.5vh }

.w-0 {width: 0%}
.w-10 {width: 10%}
.w-20 {width: 20%}
.w-30 {width: 30%}
.w-40 {width: 40%}
.w-50 {width: 50%}
.w-60 {width: 60%}
.w-70 {width: 70%}
.w-80 {width: 80%}
.w-90 {width: 90%}
.w-100 {width: 100%}

.h-0   {height: 0%}
.h-10  {height: 10%}
.h-20  {height: 20%}
.h-30  {height: 30%}
.h-40  {height: 40%}
.h-50  {height: 50%}
.h-60  {height: 60%}
.h-70  {height: 70%}
.h-80  {height: 80%}
.h-90  {height: 90%}
.h-100 {height: 100%}

.mw-0 {max-width: 0%}
.mw-10 {max-width: 10%}
.mw-20 {max-width: 20%}
.mw-30 {max-width: 30%}
.mw-40 {max-width: 40%}
.mw-50 {max-width: 50%}
.mw-60 {max-width: 60%}
.mw-70 {max-width: 70%}
.mw-80 {max-width: 80%}
.mw-90 {max-width: 90%}
.mw-100 {max-width: 100%}

.mh-0 {max-height: 0%}
.mh-10  {max-height: 10%}
.mh-20  {max-height: 20%}
.mh-30  {max-height: 30%}
.mh-40  {max-height: 40%}
.mh-50  {max-height: 50%}
.mh-60  {max-height: 60%}
.mh-70  {max-height: 70%}
.mh-80  {max-height: 80%}
.mh-90  {max-height: 90%}
.mh-100 {max-height: 100%}

// /* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';

// html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
